<template>
  <div class="relative">
    <v-select
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      dense
      :menu-props="menuProps"
      :clearable="clearable"
      clear-icon=""
      class="ui-select">
      <template #append>
        <slot v-if="!!$scopedSlots.append" name="append"/>
        <IconChevronDown v-else/>
      </template>
      <template #append-item>
        <slot name="append-item"/>
      </template>
      <template #append-outer>
        <slot name="append-outer"/>
      </template>
      <template v-if="!!$scopedSlots.counter" #counter="{counter}">
        <slot name="counter" :counter="counter"/>
      </template>
      <template v-if="!!$scopedSlots.item" #item="{item}">
        <slot name="item" :item="item"/>
      </template>
      <template #label>
        <slot name="label"/>
      </template>
      <template v-if="!!$scopedSlots.message" #message="{message}">
        <slot name="message" :message="message"/>
      </template>
      <template #no-data>
        <slot name="no-data"/>
      </template>
      <template #prepend>
        <slot name="prepend"/>
      </template>
      <template #prepend-inner>
        <slot name="prepend-inner"/>
      </template>
      <template #prepend-item>
        <slot name="prepend-item"/>
      </template>
      <template #progress>
        <slot name="progress"/>
      </template>
      <template v-if="!!$scopedSlots.selection || clearable" #selection="{item}">
        <slot v-if="!!$scopedSlots.selection" name="selection" :item="item"/>
        <UiBtn
          v-if="clearable && item"
          icon
          class="absolute clear-btn"
          @click.stop="$emit('input', null)"
        >
          <IconCancelCircle/>
        </UiBtn>
      </template>
    </v-select>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
export default {
  name: "UiSelect",
  inheritAttrs: false,
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({
        contentClass: 'ui-select-menu',
        nudgeLeft: 1
      })
    }
  },
  components: {
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    UiBtn
  },
}
</script>

<style scoped lang="scss">
::v-deep.v-input.ui-select {
  border-radius: 5px;

  &.v-input--is-disabled {
    opacity: 0.7;
  }

  .v-input__slot {
    min-height: 28px;
    height: 28px;
    padding: 0 12px 0 16px!important;
  }

  .v-select__selections {
    padding: 0;
  }

  .v-input__prepend-inner {
    flex-shrink: 0;
  }

  .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;

    svg {
      width: 14px;
    }
  }

  .v-select__selection {
    font-size: 12px;
    line-height: 14px;
    color: var(--v-gray-60-base);
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  input {
    padding-top: 3px;
    &::placeholder {
      font-size: 12px;
      line-height: 14px!important;
    }
  }

  fieldset {
    border-color: var(--v-gray-30-base);
    border-width: 1px;
  }
}

.clear-btn {
  top: 50%;
  right: 32px;
  transform: translateY(-50%);

  &::before {
    display: none;
  }

  svg {
    width: 16px;
  }
}
</style>

<style lang="scss">
.ui-select-menu {
  box-shadow: 0 4px 31px rgb(0 0 0 / 10%);
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-0-base);
}

.ui-select-menu::-webkit-scrollbar-track {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
 }
</style>
