<template>
  <UITextModal modal-name="createTemplateModal" max-width="528" :close-btn="false" @hideModal="clearForm">

    <div class="gray-0 py-6 px-4 px-sm-7">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 text-capitalize gray-100--text">New template</h5>

        <UiBtn
          fab
          color="gray-0"
          @click="clearForm(); $store.dispatch('closeModal', 'createTemplateModal');"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <v-divider class="my-5"/>

      <p class="mb-4 text-captions-1 gray-60--text">Save this configuration as a new template to re-use in future projects</p>

      <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="form"
        tag="div"
      >
        <v-form @submit.prevent="handleSubmit(submitHandler)">
          <v-flex v-if="isLoading" class="d-flex justify-center">
            <v-progress-circular
              :size="50"
              color="accent"
              indeterminate/>
          </v-flex>

          <v-flex v-else>
            <ValidationProvider class="mb-5" mode="eager" tag="div"
                                name="template name" vid="title" rules="required|min:3|max:100" v-slot="{ errors }">
              <div class="mb-2 text-body font-weight-light gray-100--text">New template name</div>
              <v-text-field
                v-model="form.title"
                dense
                outlined
                hide-details
                placeholder="Enter new template name"
                :disabled="isLoading"
                :error="!!errors.length"
              />
              <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>

            <div class="d-flex justify-end space-x-2">
              <UiBtn
                outlined
                width="112"
                color="gray-60"
                @click="clearForm(); $store.dispatch('closeModal', 'createTemplateModal');"
              >
                Cancel
              </UiBtn>
              <UiBtn
                width="140"
                type="submit"
                color="accent"
              >
                Save Template
              </UiBtn>
            </div>
          </v-flex>
        </v-form>
      </ValidationObserver>
    </div>
  </UITextModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UITextModal from "@/components/UI/UITextModal";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "CreateTemplateModal",
  components: {
    IconCancel: () => import('@/components/icons/IconCancel'),

    UITextModal,
    UiBtn,
  },
  data() {
    return {
      isLoading: false,
      form: {
        title: '',
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getTemplates',
    ]),
  },
  methods: {
    ...mapActions([
      'setTemplates',
      'updateProject'
    ]),
    submitHandler() {
      this.isLoading = true;

     this.$api.template.create(this.form)
      .then(response => {

        let templates = this.getTemplates;
        templates.push(response.data)

        this.setTemplates(templates)
        if (typeof this.$route.params.project_id !== 'undefined') {
          this.updateProject({template: [{id: response.data.id, title: response.data.title}]})
        } else {
          this.updateProject({template: response.data.id})
        }

        this.clearForm();

        this.$toast.open({
          message: 'You created new template',
          type: 'success',
          position: 'top-right'
        });

        this.$store.dispatch('closeModal', 'createTemplateModal');
      })
       .catch(error => {
         this.isLoading = false;

         // Catch Validation
         this.$nextTick(() => {
           if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
             this.$refs.form.setErrors(error.response.data);
           }
         })

         console.error(error)
       })
       .finally(() => {
         this.isLoading = false;
       })
    },
    clearForm() {
      this.form.title = '';
      this.$refs.form.reset();
    }
  }
}
</script>
