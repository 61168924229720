<template>
  <v-item-group mandatory class="d-flex align-center gap-7.5 flex-wrap" v-model="modelValue">
        <div
          v-for="item in list"
          :key="item.id"
        >
          <v-item v-slot="{ active, toggle }" :value="item.id">

            <v-card
              class="d-flex align-center flex-column toggle-card"
              tile
              flat
              width="105"
              :disabled="disabled"
              :ripple="false"
              @click="toggle"
            >
              <v-responsive :aspect-ratio="1"  class="fill-width mb-2 image-wrap" content-class="d-flex justify-center align-items-center">
                <UIImage :name-path="$config.project.productTypes.icons[item.icon_id]" class=""/>
              </v-responsive>

              <div class="text-captions-2 font-weight-semi-bold text-center px-2">{{item.title}}</div>
            </v-card>
          </v-item>
        </div>
  </v-item-group>
</template>

<script >
import UIImage from "@/components/UI/UIImage.vue";

export default {
  name: 'UiJewelryTypesGroup',
  components: {UIImage},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    modelValue: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.toggle-card){
  .image-wrap{
    border: 1px solid var(--v-gray-30-base);
    border-radius: 2px !important;
    img{
      width: 60px;
    }
  }
  &:before, &:after{
    display: none;
  }

  &.v-item--active {
    .image-wrap{
      border-color: var(--v-primary-base);
      overflow: hidden;
      &:after{
        content: '';
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right:6px solid var(--v-primary-base);
        position: absolute;
        top: -7px;
        left: -2px;
        transform: rotate(45deg);
      }
    }
  }

}
</style>
