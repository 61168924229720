<template>
  <v-radio class="ui-radio" :class="{ hovered }" off-icon="" on-icon=""
              v-bind="$attrs" v-on="$listeners" :ripple="false">
    <template #default>
      <slot/>
    </template>
    <template #label>
      <slot name="label"/>
    </template>
    <template #message>
      <slot name="message"/>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: "UiRadio",
  inheritAttrs: false,
  props: {
    hovered: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep.v-radio.ui-radio {
  &.v-input--is-disabled {
    opacity: 0.7;
  }

  .v-input--selection-controls__input {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOS4wNjgzNiIgY3k9IjguNzA1ODEiIHI9IjgiIGZpbGw9IndoaXRlIiBzdHJva2U9IiM4MTg0OEIiLz4KPC9zdmc+Cg==);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    .v-icon {
      display: none;
    }
  }

  &:not(.v-item--active) {
    &.hovered,
    &:hover {
      .v-input--selection-controls__input {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOS4wNjgzNiIgY3k9IjguNzA1ODEiIHI9IjgiIGZpbGw9IndoaXRlIiBzdHJva2U9IiM4MTg0OEIiLz4KPGNpcmNsZSBjeD0iOS4wNjgzNCIgY3k9IjguNzA1ODEiIHI9IjQuOTc4OTQiIGZpbGw9IiNEQ0REREUiIHN0cm9rZT0iIzgxODQ4QiIvPgo8L3N2Zz4K)
      }
    }
  }

    &.v-item--active {
      .v-input--selection-controls__input {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOS4wNjgzNiIgY3k9IjguNzA1ODEiIHI9IjgiIGZpbGw9IndoaXRlIiBzdHJva2U9IiM4MTg0OEIiLz4KPGNpcmNsZSBjeD0iOS4wNjgzNCIgY3k9IjguNzA1ODEiIHI9IjQuOTc4OTQiIGZpbGw9IiM2QTQ3RTkiIHN0cm9rZT0iIzMxMjFCMSIvPgo8L3N2Zz4K)
      }
    }

  .v-label {
    font-size: 14px;
    color: #000;
    font-weight: 300;
  }
}
</style>
