<template>
  <svg viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.709961 0.99353L6.70996 6.99353L12.71 0.99353" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevronDown'
}
</script>
