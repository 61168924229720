<template>
  <div class="relative">
    <UIFilesUploader
      v-if="!files.length"
      :files-formats="$config.filesystem.project.attachment.acceptedUploadFormats"
      :max-file-size="$config.filesystem.project.attachment.maxUploadFileSize"
      @onChange="addFiles"
      multiple
      :disabled="disabled"
      :maxFilesQuantity="50"
      class="d-block mb-9"
    >
      <div class="upload-form d-flex flex-column justify-center align-center fill-height cursor-pointer">
        <IconUpload width="46" class="mb-3 accent--text"/>
        <p class="mb-0 text-body font-weight-light gray-60--text">{{ emptyFirstText }}</p>
        <span class="text-body font-weight-medium accent--text border-b-s">{{ emptySecondText }}</span>
      </div>
    </UIFilesUploader>
    <div v-else class="attachments-wrap">
      <div class="mb-2 text-center text-title-3 gray-60--text font-weight-medium" v-if="title">{{ title }}</div>
      <div class="text-center font-weight-light gray-60--text" v-if="subtitle">{{ subtitle }}</div>
      <div class="attachments-grid">

        <div class="attachments-item relative d-flex flex-column" v-for="(file, idx) in files"
             :key="idx">
          <button
            @click="deleteFile(file.id)"
            v-if="!disabled"
            type="button"
            class="delete-btn absolute d-flex rounded-circle gray-0"
          >
            <IconCancelCircle width="16" class="gray-60--text"/>
          </button>

          <img alt="" :src="getFileThumbnails(file.thumbnails) || file.url" v-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.original_name))"  class="fill-width object-cover"/>
          <UIImage name-path="default-img.png" v-else class="fill-width"/>
          <div class="py-2 px-3">
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-space-between align-center mb-1">
                  <div v-bind="attrs" v-on="on"
                       class="d-flex text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis font-weight-semi-bold gray-100--text">
                      <span class="text-no-wrap overflow-hidden text-overflow-ellipsis">
                        {{ getFileName(file.original_name).name }}
                      </span>
                    <span class="flex-shrink-0">.{{ getFileName(file.original_name).ext }}</span>
                  </div>
                  <button class="flex-shrink-0 d-flex ml-2" @click="setAsThumb(file.id)"
                          :disabled="disabled"
                          type="button"
                          v-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.original_name))">
                    <component :is="file.isAvatar ? 'IconStarFilled' : 'IconStarOutlined'" width="14"
                               :class="file.isAvatar ? 'primary--text' : 'gray-60--text'"/>
                  </button>
                </div>
              </template>
              <span class="white--text font-weight-medium">{{ file.original_name }}</span>
            </v-tooltip>
            <div class="d-flex align-center justify-space-between text-captions-1 gray-60--text">
              <div class="text-captions-2">File Size: {{ formatBytes(file.size || 0) }}</div>
              <UiBtn icon plain width="auto" height="auto" class="ml-4 px-0"
                     @click="download(file)">
                <IconDownload width="14" class="gray-60--text"/>
              </UiBtn>
            </div>
          </div>
        </div>
      </div>
      <div class="upload-button d-flex justify-center space-x-2.5 py-5">
        <UIFilesUploader
          :files-formats="$config.filesystem.project.attachment.acceptedUploadFormats"
          :max-file-size="$config.filesystem.project.attachment.maxUploadFileSize"
          @onChange="addFiles"
          multiple
          :disabled="disabled"
          :maxFilesQuantity="50"
        >
          <div class="d-flex justify-center align-center " :class="!disabled ? 'cursor-pointer' : ''">
            <p class="mb-0 text-body font-weight-medium text-decoration-underline accent--text">{{ loadLabel }}</p>
          </div>
        </UIFilesUploader>
        <button class="text-body font-weight-medium text-decoration-underline accent--text" type="button" v-if="showDownload" @click="$emit('downloadAll')" :disabled="downloadLoading">Download All</button>
      </div>
    </div>
    <v-flex v-if="isLoading" class="d-flex loading-wrapper justify-center absolute inset-0 fill-width fill-height align-center">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate/>
    </v-flex>
  </div>
</template>

<script>
import UIFilesUploader from "@/components/UI/UIFilesUploader.vue";
import UIImage from "@/components/UI/UIImage.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import {mapGetters} from "vuex";
import {formatBytes, getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";

export default {
  name: "UploadAttachments",
  components: {
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconStarFilled: () => import('@/components/icons/IconStarFilled'),
    IconStarOutlined: () => import('@/components/icons/IconStarOutlined'),

    UiBtn,
    UIImage,
    UIFilesUploader
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Project Specifications'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: 'These are the files you’ve uploaded for designers to follow'
    },
    loadLabel: {
      type: String,
      default: '+Add More'
    },
    emptyFirstText: {
      type: String,
      default: 'Drag and Drop here'
    },
    emptySecondText: {
      type: String,
      default: 'or browse to add'
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    downloadLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  watch: {
    isLoading() {
      this.$emit('fileLoading', this.isLoading)
    }
  },
  computed: {
    ...mapGetters([
      'getProject'
    ]),
    files: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    getFileThumbnails,
    getFileExtension,
    formatBytes,
    getFileName,
    async addFiles(files) {
      this.isLoading = true;
      let formData = new FormData();

      files.forEach((file) => {
        formData.append("file[]", file);
      })

      try {
        const res = await this.$api.filesystem.create(formData)

        this.setDefaultAvatar([...this.files, ...res.data.map((file) => ({...file, isAvatar: false}))])

        this.$emit('successUpload', this.files)

      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteFile(file_id) {
      await this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete this file?',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.filesystem.delete(file_id)
              const files = this.files.filter(file => file.id !== file_id);

              this.setDefaultAvatar(files)

              this.$emit('deleteFile', file_id)

              this.$toast.open({
                message: 'File deleted successfully',
                type: 'info',
                position: 'top-right'
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },

    async download(file) {
      if(!file) return
      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
    },
    setAsThumb(file_id) {
      if(this.disabled) return
      this.files.forEach(file => {
        if(file.id === file_id) {
          file.isAvatar = !file.isAvatar
        } else {
          file.isAvatar = false
        }
      })
    },
    setDefaultAvatar(files) {
      const filesHasAvatar = files.some(file => file.isAvatar)
      this.files = files.reduce((acc, file) => {
        if(filesHasAvatar)  {
          acc.push(file)
        } else {
          const accHasAvatar = acc.some(file => file.isAvatar)
          acc.push({
            ...file,
            isAvatar: this.$config.filesystem.fileTypes.image.includes(getFileExtension(file.original_name)) && !accHasAvatar
          })
        }
        return acc
      }, [])
    }
  }
}
</script>

<style scoped lang="scss">
.loading-wrapper{
  background-color: var(--v-gray-10-base);
  border: 1px dashed var(--v-gray-60-base) ;
  border-radius: 2px;
}
.upload-form {
  height: 216px;
  background-color: var(--v-gray-10-base);
  border: 1px dashed var(--v-gray-60-base);
  border-radius: 2px;
}

.attachments-wrap {
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-10-base);
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 20px;
}

.attachments-item {
  width: 100%;
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-0-base);
  border-radius: 2px;

  img {
    height: 110px;
  }

  .delete-btn {
    top: -8px;
    right: -8px;
  }
}

.upload-button {
  border-top: 1px solid var(--v-gray-30-base);
}

.attachments-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 20px;
  column-gap: 21px;
  padding: 20px 38px;
}
</style>
