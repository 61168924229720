<template>
  <v-combobox
    v-bind="$attrs"
    v-on="$listeners"
    :search-input.sync="searchTag"
    multiple
    persistent-hint
    hide-details
    hide-selected
    dense
    outlined
    :disabled="disabled"
    append-icon=""
    chips
    :menu-props="{contentClass: 'ui-autocomplete-menu'}"
    class="tag-select"
  >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        label
        outlined
        color="accent"
        :disabled="disabled"
        small
      >
        <button type="button" class="d-flex mr-2" @click="parent.selectItem(item)">
          <IconCancelCircle width="12"/>
        </button>
        <span>{{ item }}</span>
      </v-chip>
    </template>
    <template #append>
      <IconChevronDown width="14" class="icon-transition" style="margin-top: 2px;"/>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ searchTag }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import IconCancelCircle from "@/components/icons/IconCancelCircle.vue";
import IconChevronDown from "@/components/icons/IconChevronDown.vue";

export default {
  name: "UiMultipleSelect",
  components: {IconChevronDown, IconCancelCircle},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      searchTag: '',
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.tag-select) {
  &.v-input--is-focused {
    .v-input__append-inner svg {
      transform: rotate(-180deg);
    }
  }

  .v-input__slot {
    padding-right: 12px!important;

    input {
      max-height: inherit!important;
      padding: 2px 0!important;
    }
  }

  &.v-input--is-dirty .v-input__slot {
    padding-left: 2px!important;
  }

  .v-select__selections {
    min-height: 28px!important;
    padding: 0!important;
  }

  .v-chip {
    height: 20px;
    padding: 0 8px;
    margin: 2px 4px;

    button {
      margin-right: 6px;
    }
  }
}
</style>
