<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4727 6.08247C10.8633 5.69195 10.8633 5.05878 10.4727 4.66826C10.0822 4.27774 9.44904 4.27774 9.05852 4.66826L10.4727 6.08247ZM5.30852 8.41826C4.91799 8.80878 4.91799 9.44195 5.30852 9.83247C5.69904 10.223 6.33221 10.223 6.72273 9.83247L5.30852 8.41826ZM6.72273 4.66826C6.33221 4.27774 5.69904 4.27774 5.30852 4.66826C4.91799 5.05878 4.91799 5.69195 5.30852 6.08247L6.72273 4.66826ZM9.05852 9.83247C9.44904 10.223 10.0822 10.223 10.4727 9.83247C10.8633 9.44195 10.8633 8.80878 10.4727 8.41826L9.05852 9.83247ZM13.1406 7.25037C13.1406 10.1499 10.7901 12.5004 7.89062 12.5004V14.5004C11.8947 14.5004 15.1406 11.2544 15.1406 7.25037H13.1406ZM7.89062 12.5004C4.99113 12.5004 2.64062 10.1499 2.64062 7.25037H0.640625C0.640625 11.2544 3.88656 14.5004 7.89062 14.5004V12.5004ZM2.64062 7.25037C2.64062 4.35087 4.99113 2.00037 7.89062 2.00037V0.000366211C3.88656 0.000366211 0.640625 3.2463 0.640625 7.25037H2.64062ZM7.89062 2.00037C10.7901 2.00037 13.1406 4.35087 13.1406 7.25037H15.1406C15.1406 3.2463 11.8947 0.000366211 7.89062 0.000366211V2.00037ZM9.05852 4.66826L5.30852 8.41826L6.72273 9.83247L10.4727 6.08247L9.05852 4.66826ZM5.30852 6.08247L9.05852 9.83247L10.4727 8.41826L6.72273 4.66826L5.30852 6.08247Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCancelCircle'
}
</script>
