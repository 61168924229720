<template>
  <div class="page-header" :style="sticky ? styleTop: ''" :class="[sticky ? 'sticky': 'relative', border ? 'border' : '']">
    <v-container :style="{maxWidth: maxContainerWidth ? convertToUnit(maxContainerWidth): null}">
      <slot/>
    </v-container>
  </div>
</template>

<script>
import {convertToUnit} from "@/utils/helpers";

export default {
  name: "PageHeader",
  methods: {convertToUnit},
  props: {
    sticky: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    maxContainerWidth: {
      type: [Number, null],
      default: null
    }
  },
  computed: {
    styleTop() {
      return {
        top: `${this.$vuetify.application.top}px`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-header {
  background: var(--v-gray-0-base);
  z-index: 10;
  &.border {
    border-bottom: 1px solid var(--v-gray-30-base);
  }
  &.sticky {
    position: sticky;
  }
}
</style>
