<template>
  <div class="space-y-5">
    <div v-for="(block, idx) in blockSchema" :key="idx" class="space-y-5">
      <h3 class="mb-5 text-body-lg font-weight-medium gray-100--text" v-if="block.title">{{ block.title }}</h3>
      <template v-for="(blockChild, blockChildIdx) in block.data">
        <div :key="blockChildIdx">
          <div v-if="block.title" class="mb-2 text-body font-weight-semi-bold gray-100--text">{{blockChild.title}}</div>
          <v-row>
            <v-col v-for="(input, inputIdx) in blockChild.data" :key="inputIdx" :cols="input.field_size">
              <div class="mb-2 text-body font-weight-light gray-100--text">{{ input.label }}</div>
              <component :is="componentsAlias[input.data_type]" v-model="form[input.sku]"
                         dense
                         outlined
                         hide-details
                         :suffix="input.suffix"
                         :prefix="input.prefix"
                         no-resize
                         rows="3"
                         :disabled="disabled"
                         :items="input.values"
                         :placeholder="input.placeholder"
                         @change="onChange"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {VTextField, VCombobox, VTextarea} from 'vuetify/lib'
import UiMultipleSelect from "@/components/UI/UiMultipleSelect.vue";
export default {
  name: 'IntakeForm',
  props: {
    formSchema: {
      type: Object,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultForm: {
      type: Object,
      require: true
    }
  },
  components: {UiMultipleSelect, VTextField, VCombobox, VTextarea },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.defaultForm)) || {},
      blockSchema: null,
      componentsAlias: {
        text: 'v-text-field',
        select: 'UiMultipleSelect',
        textarea : 'v-textarea'
      }
    }
  },
  watch: {
    'formSchema': {
      handler() {
        if (this.formSchema) {
          this.form = JSON.parse(JSON.stringify(this.defaultForm || {}))
          this.blockSchema = this.flatObj(this.formSchema.project_intake_fieldset.fieldset)
          this.onChange()
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    flatObj(obj) {
      return Object.values(obj).reduce((acc, item) => {
        if (item.data) {
          acc.push({...item, data: this.flatObj(item.data)})
        } else {
          this.form[item.sku] = item.data_type === 'select' ? this.defaultForm[item.sku] || [] : this.defaultForm[item.sku] || ''
          acc.push(item)
        }
        return acc.sort((a, b) => a.sort_order - b.sort_order)
      }, [])
    },
    onChange() {
      this.$emit('changeForm', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field__suffix), :deep(.v-text-field__prefix){
  font-size: 12px;
  color: var(--v-gray-60-base);
}
</style>
